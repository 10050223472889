import { ApolloProvider } from '@apollo/client';
import { getApolloClient } from 'apollo-client';
import { createContext, FC, useState } from 'react';

const ApolloClientContext = createContext(null);

const ApolloClientProvider: FC = ({ children }) => {
    const [authToken, setAuthToken] = useState('');

    const apolloClient = getApolloClient(authToken, setAuthToken);

    return (
        <ApolloClientContext.Provider value={{ authToken, setAuthToken }}>
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        </ApolloClientContext.Provider>
    );
};

export default ApolloClientProvider;
export { ApolloClientContext };
