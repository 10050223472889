import Heading from '@/components/common/Heading';
import Loading from '@/components/common/Loading';
import Modal from '@/components/common/Modal';
import Text from '@/components/common/Text';
import { CustomerInfoOutput } from '@/graphql/hooks';
import React, { createContext, FC, useState } from 'react';

interface FullscreenLoadingInfo {
    title: string;
    text?: string;
}

interface IAppUserContext {
    user: CustomerInfoOutput;
    setUser;
    enableFullscreenLoading: (data: FullscreenLoadingInfo) => void;
    disableFullscreenLoading: () => void;
}

const AppUserContext = createContext<IAppUserContext>(null);

const AppUserProvider: FC = ({ children }) => {
    const [user, setUser] = useState<CustomerInfoOutput>();
    const [loading, setLoading] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState<FullscreenLoadingInfo>(null);

    const enableFullscreenLoading = (data: FullscreenLoadingInfo) => {
        setLoadingInfo(data);
        setLoading(true);
    };

    const disableFullscreenLoading = () => {
        setLoading(false);
        setLoadingInfo(null);
    };

    const context = {
        user,
        setUser,
        enableFullscreenLoading,
        disableFullscreenLoading,
    };

    return (
        <AppUserContext.Provider value={context}>
            {children}

            <Modal
                isOpen={loading}
                onClose={() => null}
                closingDisabled
                labelledBy="user-loading-modal"
                fullWidth
            >
                <div className="flex">
                    <Heading id="user-loading-modal" level="3" noTopMargin>
                        {loadingInfo?.title}
                    </Heading>

                    <Loading dark className="h-lg w-lg ml-md" />
                </div>

                <Text>{loadingInfo?.text}</Text>
            </Modal>
        </AppUserContext.Provider>
    );
};

export default AppUserProvider;
export { AppUserContext };
