import React, { FC } from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import Cross from '@/assets/icons/Cross';
import { useTranslation } from 'react-i18next';
import { useTransition, animated } from 'react-spring';

export interface ModalProps {
    labelledBy?: string;
    isOpen: boolean;
    onClose: () => void;
    position?: 'center' | 'top';
    fullWidth?: boolean;
    className?: string;
    closingDisabled?: boolean;
}

const Modal: FC<ModalProps> = ({
    labelledBy,
    isOpen = false,
    onClose,
    position = 'top',
    fullWidth = false,
    className = '',
    closingDisabled = false,
    children,
}) => {
    const { t } = useTranslation();

    const AnimatedDialogOverlay = animated(DialogOverlay);
    const AnimatedDialogContent = animated(DialogContent);

    const transitions = useTransition(isOpen, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 100,
        },
        reverse: isOpen,
    });

    return (
        <>
            {transitions(({ opacity }, item) => (
                <AnimatedDialogOverlay
                    style={{
                        opacity: opacity,
                    }}
                    className={`left-0 right-0 top-0 bottom-0 flex justify-center bg-modal-overlay p-sm tablet:p-lg overflow-y-scroll
                        ${position === 'center' ? 'items-center' : ''}
                        ${
                            position === 'top'
                                ? 'items-start tablet:pt-modal-top'
                                : ''
                        }
                    `}
                    isOpen={item}
                    onDismiss={onClose}
                >
                    <AnimatedDialogContent
                        className={`relative max-w-modal min-w-modal bg-white rounded-sm p-md tablet:px-4xl tablet:py-3xl
                            ${fullWidth ? 'w-full' : ''}
                            ${className}
                        `}
                        aria-labelledby={labelledBy}
                    >
                        {!closingDisabled && (
                            <button
                                onClick={onClose}
                                className="absolute right-0 top-0 p-base bg-neutral-500 rounded-tr-sm"
                            >
                                <VisuallyHidden>
                                    {t('common:closeModal')}
                                </VisuallyHidden>
                                <Cross size={12} />
                            </button>
                        )}
                        {children}
                    </AnimatedDialogContent>
                </AnimatedDialogOverlay>
            ))}
        </>
    );
};

export default Modal;
