import React, { FC } from 'react';

export type IPriority = '1' | '2' | '3' | '4' | '5' | '6';

interface Props extends React.HTMLProps<HTMLHeadingElement> {
    level?: IPriority;
    styledAs?: IPriority;
    noTopMargin?: boolean;
    noBottomMargin?: boolean;
}

const Heading: FC<Props> = ({
    children,
    className,
    level = 1,
    styledAs: styledAsProp,
    noTopMargin = false,
    noBottomMargin = false,
}) => {
    const headingTags = {
        1: 'h1',
        2: 'h2',
        3: 'h3',
        4: 'h4',
        5: 'h5',
        6: 'h6',
    };
    const HeadingTag = headingTags[level];

    let styledAs = String(styledAsProp);

    if (!styledAsProp) {
        styledAs = String(level);
    }

    return (
        <HeadingTag
            className={`font-header tracking-header
            ${styledAs === '1' ? 'text-3xl tablet:text-6xl' : ''}
            ${styledAs === '2' ? 'text-2xl tablet:text-5xl' : ''}
            ${styledAs === '3' ? 'text-xl tablet:text-4xl' : ''}
            ${styledAs === '4' ? 'text-lg tablet:text-3xl' : ''}
            ${styledAs === '5' ? 'text-md tablet:text-2xl' : ''}
            ${styledAs === '6' ? 'text-sm tablet:text-xl' : ''}

            ${noTopMargin ? '' : 'mt-xl tablet:mt-xxl'}
            ${noBottomMargin ? '' : 'mb-lg tablet:mb-xl'}
            ${className}
        `}
        >
            {children}
        </HeadingTag>
    );
};

export default Heading;
