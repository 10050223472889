import React, { createContext, FC, useState } from 'react';

interface Context {
    menuOpen;
    setMenuOpen;
}

const MenuContext = createContext<Context>(null);

const MenuProvider: FC = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const context = {
        menuOpen,
        setMenuOpen,
    };

    return (
        <MenuContext.Provider value={context}>{children}</MenuContext.Provider>
    );
};

const withMenuProvider: (
    WrappedComponent: FC,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (props: any) => JSX.Element = (WrappedComponent: FC) => {
    return props => {
        return (
            <MenuProvider>
                <WrappedComponent {...props} />
            </MenuProvider>
        );
    };
};

export default MenuProvider;
export { MenuContext, withMenuProvider };
