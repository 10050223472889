import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import all namespaces (for the default language, only)
import account from './locales/en-GB/account.json';
import auth from './locales/en-GB/auth.json';
import common from './locales/en-GB/common.json';
import dashboard from './locales/en-GB/dashboard.json';
import medication from './locales/en-GB/medication.json';
import requestMedication from './locales/en-GB/requestMedication.json';
import orders from './locales/en-GB/orders.json';

const namespaces = {
    account,
    auth,
    common,
    dashboard,
    medication,
    requestMedication,
    orders,
};

export const resources = {
    en: namespaces,
} as const;

i18n.use(initReactI18next).init({
    lng: 'en-GB',
    ns: Object.keys(namespaces),
    resources,
});
