import jwtDecode from 'jwt-decode';
import { JWT } from 'types/security';
import cookie from 'cookie';

export const getCookie = (cookies: string, name: string): string => {
    if (!cookies) {
        return null;
    }

    return cookie.parse(cookies)[name];
};

export const setCookie = (name: string, cookie: string): void => {
    const { exp } = jwtDecode(cookie) as JWT;
    const cookieExpiration = new Date(exp * 1000).toUTCString();

    document.cookie = `${name}=${cookie}; path=/; expires=${cookieExpiration}; samesite=strict;`;
};

export const removeCookie = (name: string): void => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};
