import React, { FC } from 'react';

interface Props {
    size?: number;
    className?: string;
}

const Cross: FC<Props> = ({
    size = 40,
    className = 'text-neutral-900',
}: Props) => {
    return (
        <svg
            className={`fill-current ${className}`}
            width={size}
            height={size}
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.65381 0.524829C2.27305 0.144068 1.65571 0.144068 1.27495 0.524829C0.894191 0.90559 0.894191 1.52293 1.27495 1.90369L5.87114 6.49988L1.27495 11.0961C0.89419 11.4768 0.894189 12.0942 1.27495 12.4749C1.65571 12.8557 2.27305 12.8557 2.65381 12.4749L7.25 7.87874L11.8462 12.4749C12.227 12.8557 12.8443 12.8557 13.2251 12.4749C13.6058 12.0942 13.6058 11.4768 13.2251 11.0961L8.62886 6.49988L13.2251 1.90369C13.6058 1.52293 13.6058 0.90559 13.2251 0.524829C12.8443 0.144067 12.227 0.144067 11.8462 0.524829L7.25 5.12102L2.65381 0.524829Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default React.memo(Cross);
