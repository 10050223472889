import '../styles/index.css';
import '../styles/components.css';
import '../i18n/i18n-config';

import ApolloClientProvider from '@/contexts/ApolloClientProvider';
import AppUserProvider from '@/contexts/AppUserProvider';
import { AppProps } from 'next/app';
import { FC } from 'react';
import useHelpWidget from '@/hooks/useHelpWidget';
import MenuProvider from '@/contexts/MenuProvider';

const MyApp: FC<AppProps> = ({ Component, pageProps }: AppProps) => {
    const { ScriptTag } = useHelpWidget({ showFloatingButton: true });

    return (
        <MenuProvider>
            <ApolloClientProvider>
                <AppUserProvider>
                    <Component {...pageProps} />
                    <ScriptTag />
                </AppUserProvider>
            </ApolloClientProvider>
        </MenuProvider>
    );
};

export default MyApp;
