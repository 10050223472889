import { useState } from 'react';
import Script from 'next/script';
import { FC } from 'react';
import { useEffect } from 'react';

interface ExtendedWindow extends Window {
    zESettings: any;
    zE: any;
}

declare let window: ExtendedWindow;

interface Props {
    showFloatingButton?: boolean;
}

interface ReturnProps {
    ScriptTag: FC;
    scriptLoaded: boolean;
    showWidget: () => void;
}

const useHelpWidget = ({ showFloatingButton = false }: Props): ReturnProps => {
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

    useEffect(() => {
        window.zESettings = {
            launcher: {
                mobile: {
                    labelVisible: false,
                },
            },
            webWidget: {
                color: {
                    launcher: '#1D5CFF',
                    header: '#1D5CFF',
                    button: '#1D5CFF',
                    articleLinks: '#1D5CFF',
                },
            },
        };
    }, []);

    const helpWidgetLoaded = () => {
        setScriptLoaded(true);

        if (!showFloatingButton) {
            window.zE('webWidget', 'hide');
        }
    };

    const showWidget = () => {
        window.zE.activate({ hideOnClose: !showFloatingButton });
    };

    const ScriptTag: FC = () => (
        <Script
            src="https://static.zdassets.com/ekr/snippet.js?key=f34bce9c-2c9f-43b1-b528-f059fdb0a9ec"
            onLoad={helpWidgetLoaded}
            id="ze-snippet"
        />
    );

    return {
        ScriptTag,
        scriptLoaded,
        showWidget,
    };
};

export default useHelpWidget;
