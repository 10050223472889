export const COOKIE_KEY = 'sop_token';

export interface JWT {
    exp: number;
}

export function hasTokenExpired(tokenExpiry: number): boolean {
    const now = Date.now();
    const dueToExpire = tokenExpiry * 1000;

    if (now >= dueToExpire) {
        return true;
    }

    return false;
}

export function isTokenDueToExpire(tokenExpiry: number): boolean {
    const now = Date.now();
    const early = 5 * 60 * 1000; // 5mins
    const dueToExpire = tokenExpiry * 1000 - early;

    if (now >= dueToExpire) {
        return true;
    }

    return false;
}
