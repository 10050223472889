import {
    ApolloClient,
    InMemoryCache,
    NormalizedCacheObject,
    HttpLink,
    ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import jwtDecode from 'jwt-decode';
import { RefreshTokenMutationResult } from './graphql/hooks';
import { removeCookie, setCookie } from './services/cookies.service';
import { COOKIE_KEY, isTokenDueToExpire, JWT } from './types/security';

export const getApolloClient = (
    authToken = null,
    setAuthToken = (newAuthToken: string) => null,
): ApolloClient<NormalizedCacheObject> => {
    const refreshToken = async () => {
        try {
            const fetchResult = await fetch(process.env.NEXT_PUBLIC_GQL_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    operationName: 'refreshToken',
                    query: `
                        mutation refreshToken {
                            refreshToken
                        }
                    `,
                }),
            });
            const fetchJson =
                (await fetchResult.json()) as RefreshTokenMutationResult;

            return fetchJson.data.refreshToken;
        } catch {
            return '';
        }
    };

    const apolloHttpLink = new HttpLink({
        uri: process.env.NEXT_PUBLIC_GQL_URL,
    });

    const apolloAuthLink = setContext(async (_, { headers }) => {
        if (authToken) {
            const { exp } = jwtDecode(authToken) as JWT;

            if (isTokenDueToExpire(exp)) {
                try {
                    const refreshedToken = await refreshToken();

                    setCookie(COOKIE_KEY, refreshedToken);
                    setAuthToken(refreshedToken);
                } catch {
                    removeCookie(COOKIE_KEY);
                    setAuthToken(null);

                    return;
                }
            }

            return {
                headers: {
                    ...headers,
                    authorization: `Bearer ${authToken}`,
                },
            };
        } else {
            return {
                headers,
            };
        }
    });

    const errorLink = onError(({ networkError }) => {
        if ('statusCode' in networkError && networkError.statusCode === 401) {
            if (authToken) {
                refreshToken()
                    .then(token => {
                        setCookie(COOKIE_KEY, token);
                        setAuthToken(token);
                    })
                    .catch(() => {
                        removeCookie(COOKIE_KEY);
                        setAuthToken(null);
                    });
            }
        }
    });

    const apolloClient = new ApolloClient({
        ssrMode: typeof window === 'undefined',
        link: ApolloLink.from([errorLink, apolloAuthLink, apolloHttpLink]),
        cache: new InMemoryCache(),
        connectToDevTools: process.env.NODE_ENV === 'development',
        defaultOptions: {
            mutate: {
                errorPolicy: 'all',
            },
            query: {
                errorPolicy: 'all',
            },
        },
    });

    return apolloClient;
};
