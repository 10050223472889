import React from 'react';
import LoadingSpinner from '@/assets/icons/LoadingSpinner';

interface Props {
    dark?: boolean;
    className?: string;
}

const Loading: React.FC<Props> = ({ dark = false, className }: Props) => {
    return (
        <div className={`motion-safe:animate-spin ${className}`}>
            <LoadingSpinner dark={dark} />
        </div>
    );
};

export default React.memo(Loading);
