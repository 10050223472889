import React, { FC } from 'react';

interface Props {
    className?: string;
    inline?: boolean;
    isBold?: boolean;
    size?: 'normal' | 'small' | 'intro';
}

const Text: FC<Props> = ({
    children,
    className,
    inline = false,
    isBold,
    size = 'normal',
}) => {
    const Tag = inline ? 'span' : 'p';

    return (
        <Tag
            className={`
                ${className}

                text-neutral-700 leading-text

                ${size === 'normal' ? 'text-xsm' : ''}

                ${size === 'small' ? 'text-2xsm' : ''}

                ${size === 'intro' ? 'text-md' : ''}

                ${!inline ? 'mb-base' : ' '}

                ${isBold ? 'font-bold' : ' '}
            `}
        >
            {children}
        </Tag>
    );
};

export default Text;
