// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT =
    process.env.NEXT_PUBLIC_ENVIRONMENT || process.env.ENVIRONMENT;

if (ENVIRONMENT !== 'local') {
    Sentry.init({
        dsn:
            SENTRY_DSN ||
            'https://feb74a4dedac4bf9acbce9dd1091fbc5@o404395.ingest.sentry.io/5795495',
        environment: ENVIRONMENT,
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: 0.2,
    });
}
